.momos-input {
  border-radius: 550px !important;
  height: 50px;
  font-family: var(--font-family-regular);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.momos-small-input {
  border-radius: 30px !important;
  font-family: var(--font-family-regular);
  font-style: normal;
  font-weight: 500;
  height: 40px;
  font-size: 14px;
  line-height: 20px;
}
.momos-small-input .ant-input-number-input {
  height: 40px;
}
.ant-input[disabled] {
  background: var(--grey-light);
  color: var(--grey);
}

.momos-textarea {
  font-family: var(--font-family-regular);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--dark-grey);
  background: #ffffff;
  border: 1px solid #a0a0a0;
  box-sizing: border-box;
  border-radius: 25px;
}

.ant-input.ant-input-lg {
  line-height: 2 !important;
  font-size: 14px;
}
