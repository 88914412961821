@import '~antd/lib/style/themes/default.less';
@import '~antd/dist/antd.less';
@import './ant-theme.less';

.ql-editor {
  .ql-size-small {
    font-size: 0.75em;
  }
  .ql-size-large {
    font-size: 1.5em;
  }
  .ql-size-huge {
    font-size: 2.5em;
  }

  .ql-font-gilroy_bold {
    font-family: 'Gilroy-Bold' !important;
  }
  .ql-font-arial {
    font-family: 'Arial' !important;
  }
  .ql-font-gilroy {
    font-family: 'Gilroy-Regular' !important;
  }
  .ql-font-montserrat {
    font-family: 'Montserrat-Regular' !important;
  }
  .ql-font-druk_bold_regular {
    font-family: 'Druk-Bold-Regular' !important;
  }
  .ql-font-baby_school_italic {
    font-family: 'Baby-School-Italic' !important;
  }

  .ql-align-center {
    text-align: center;
  }
  .ql-align-justify {
    text-align: justify;
  }
  .ql-align-right {
    text-align: right;
  }
}

.align-center {
  align-items: center;
}

.justify-end {
  justify-content: flex-end;
}
