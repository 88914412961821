@font-face {
  font-family: 'Montserrat-Regular';
  src: local('Montserrat-Regular'), url('/fonts/Montserrat-Regular.ttf') format('truetype');
}

/* Noto Sans fonts */
@font-face {
  font-family: 'NotoSans-Regular';
  src: local('NotoSans-Regular'), url('/fonts/NotoSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSans-Bold';
  src: local('NotoSans-Bold'), url('/fonts/NotoSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSans-Italic';
  src: local('NotoSans-Italic'), url('/fonts/NotoSans-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSans-BoldItalic';
  src: local('NotoSans-BoldItalic'), url('/fonts/NotoSans-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSans-Light';
  src: local('NotoSans-Light'), url('/fonts/NotoSans-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSans-LightItalic';
  src: local('NotoSans-LightItalic'), url('/fonts/NotoSans-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSans-Medium';
  src: local('NotoSans-Medium'), url('/fonts/NotoSans-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSans-MediumItalic';
  src: local('NotoSans-MediumItalic'), url('/fonts/NotoSans-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSans-SemiBold';
  src: local('NotoSans-SemiBold'), url('/fonts/NotoSans-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSans-SemiBoldItalic';
  src: local('NotoSans-SemiBoldItalic'), url('/fonts/NotoSans-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSans-ExtraBold';
  src: local('NotoSans-ExtraBold'), url('/fonts/NotoSans-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSans-ExtraBoldItalic';
  src: local('NotoSans-ExtraBoldItalic'), url('/fonts/NotoSans-ExtraBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSans-ExtraLight';
  src: local('NotoSans-ExtraLight'), url('/fonts/NotoSans-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSans-ExtraLightItalic';
  src: local('NotoSans-ExtraLightItalic'), url('/fonts/NotoSans-ExtraLightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSans-Thin';
  src: local('NotoSans-Thin'), url('/fonts/NotoSans-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSans-ThinItalic';
  src: local('NotoSans-ThinItalic'), url('/fonts/NotoSans-ThinItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSans';
  src: local('NotoSans-Thin'), url('/fonts/NotoSans-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSans';
  src: local('NotoSans-ThinItalic'), url('/fonts/NotoSans-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'NotoSans';
  src: local('NotoSans-ExtraLight'), url('/fonts/NotoSans-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSans';
  src: local('NotoSans-ExtraLightItalic'), url('/fonts/NotoSans-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'NotoSans';
  src: local('NotoSans-Light'), url('/fonts/NotoSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSans';
  src: local('NotoSans-LightItalic'), url('/fonts/NotoSans-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'NotoSans';
  src: local('NotoSans-Regular'), url('/fonts/NotoSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSans';
  src: local('NotoSans-Italic'), url('/fonts/NotoSans-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'NotoSans';
  src: local('NotoSans-Medium'), url('/fonts/NotoSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSans';
  src: local('NotoSans-MediumItalic'), url('/fonts/NotoSans-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'NotoSans';
  src: local('NotoSans-SemiBold'), url('/fonts/NotoSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSans';
  src: local('NotoSans-SemiBoldItalic'), url('/fonts/NotoSans-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'NotoSans';
  src: local('NotoSans-Bold'), url('/fonts/NotoSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSans';
  src: local('NotoSans-BoldItalic'), url('/fonts/NotoSans-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'NotoSans';
  src: local('NotoSans-ExtraBold'), url('/fonts/NotoSans-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSans';
  src: local('NotoSans-ExtraBoldItalic'), url('/fonts/NotoSans-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

/* Gilroy fonts */

@font-face {
  font-family: 'Gilroy-Regular';
  src: local('Gilroy-Regular'), url('/fonts/Gilroy-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Gilroy-SemiBold';
  src: local('Gilroy-SemiBold'), url('/fonts/Gilroy-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Gilroy-Medium';
  src: local('Gilroy-Medium'), url('/fonts/Gilroy-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Gilroy-Light';
  src: local('Gilroy-Light'), url('/fonts/Gilroy-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Gilroy-Bold';
  src: local('Gilroy-Bold'), url('/fonts/Gilroy-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Gilroy-Heavy';
  src: local('Gilroy-Heavy'), url('/fonts/Gilroy-Heavy.ttf') format('truetype');
}

@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy-Light.ttf') format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy-Heavy.ttf') format('truetype');
  font-weight: 800;
}

@font-face {
  font-family: 'Druk-Bold-Regular';
  src: local('Druk-Bold-Regular'), url('/fonts/Druk-Bold-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Barlow-Bold';
  src: local('Barlow-Bold'), url('/fonts/Barlow-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Baby-School-Italic';
  src: local('Baby-School-Italic'), url('/fonts/Baby-School-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Stump-Closed';
  src: local('Stump-Closed'), url('/fonts/Stump-Closed.ttf') format('truetype');
}

@font-face {
  font-family: 'Stump-One';
  src: local('Stump-One'), url('/fonts/Stump-One.ttf') format('truetype');
}

@font-face {
  font-family: 'Stump-Shadow';
  src: local('Stump-Shadow'), url('/fonts/Stump-Shadow.ttf') format('truetype');
}

@font-face {
  font-family: 'Stump-Textured';
  src: local('Stump-Textured'), url('/fonts/Stump-Textured.ttf') format('truetype');
}

@font-face {
  font-family: 'PTSans-Regular';
  src: local('PTSans-Regular'), url('/fonts/PTSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'PTSans-Italic';
  src: local('PTSans-Italic'), url('/fonts/PTSans-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'PTSans-Bold';
  src: local('PTSans-Bold'), url('/fonts/PTSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'PTSans-Bold-Italic';
  src: local('PTSans-Bold-Italic'), url('/fonts/PTSans-Bold-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Futura-Book';
  src: local('Futura-Book'), url('/fonts/Futura-Book.ttf') format('truetype');
}

@font-face {
  font-family: 'AmsiProCondensedBlack';
  src: local('AmsiProCondensedBlack'), url('/fonts/AmsiProCondensedBlack.ttf') format('truetype');
}

@font-face {
  font-family: 'AmsiProCondensedRegular';
  src: local('AmsiProCondensedRegular'), url('/fonts/AmsiProCondensedRegular.ttf') format('truetype');
}

@font-face {
  font-family: 'Antarctican-Headline';
  src: local('Antarctican-Headline'), url('/fonts/Antarctican-Headline.ttf') format('truetype');
}

@font-face {
  font-family: 'Kisna';
  src: local('Kisna'), url('/fonts/kisna-regular-webfont.woff') format('truetype');
}

@font-face {
  font-family: 'Fieldwork Geo';
  src: local('Fieldwork Geo'), url('/fonts/fieldwork-geo-regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Archer Book';
  src: url('/fonts/Archer-Book/Archer-Book.ttf') format('truetype');
}

@font-face {
  font-family: 'Archer Book';
  src: url('/fonts/Archer-Book/Archer-BookItalic.ttf') format('truetype');
  font-style: italic;
}

@font-face {
  font-family: 'Archer';
  src: url('/fonts/Archer/Archer-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Archer';
  src: url('/fonts/Archer/Archer-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Archer';
  src: url('/fonts/Archer/Archer-Semibold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Archer';
  src: url('/fonts/Archer/Archer-SemiboldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Archer';
  src: url('/fonts/Archer/Archer-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Better Times';
  src: url('/fonts/BetterTimes/Better-Times.ttf') format('truetype');
}

@font-face {
  font-family: 'Better Times Alt';
  src: url('/fonts/BetterTimes/Better-Times-Alt.ttf') format('truetype');
}

@font-face {
  font-family: 'Quincy CF Black';
  src: url('/fonts/QuincyCFBlack/Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Quincy CF Black';
  src: url('/fonts/QuincyCFBlack/BlackItalic.ttf') format('truetype');
  font-style: italic;
}

@font-face {
  font-family: 'Quincy CF Text';
  src: url('/fonts/QuincyCFText/Text.ttf') format('truetype');
}

@font-face {
  font-family: 'Quincy CF Text';
  src: url('/fonts/QuincyCFText/TextItalic.ttf') format('truetype');
  font-style: italic;
}

@font-face {
  font-family: 'Quincy CF';
  src: url('/fonts/QuincyCF/Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Quincy CF';
  src: url('/fonts/QuincyCF/BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Quincy CF';
  src: url('/fonts/QuincyCF/ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Quincy CF';
  src: url('/fonts/QuincyCF/ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Quincy CF';
  src: url('/fonts/QuincyCF/Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Quincy CF';
  src: url('/fonts/QuincyCF/LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Quincy CF';
  src: url('/fonts/QuincyCF/Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Quincy CF';
  src: url('/fonts/QuincyCF/MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Quincy CF';
  src: url('/fonts/QuincyCF/Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Quincy CF';
  src: url('/fonts/QuincyCF/RegularItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Quincy CF';
  src: url('/fonts/QuincyCF/Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Quincy CF';
  src: url('/fonts/QuincyCF/ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Ringside';
  src: url('/fonts/Ringside/Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Ringside';
  src: url('/fonts/Ringside/BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Ringside';
  src: url('/fonts/Ringside/Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Ringside';
  src: url('/fonts/Ringside/LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Ringside';
  src: url('/fonts/Ringside/Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Ringside';
  src: url('/fonts/Ringside/MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Ringside';
  src: url('/fonts/Ringside/Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Ringside';
  src: url('/fonts/Ringside/SemiboldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Ringside';
  src: url('/fonts/Ringside/Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Ringside';
  src: url('/fonts/Ringside/ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Ringside';
  src: url('/fonts/Ringside/Ultra.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Ringside';
  src: url('/fonts/Ringside/UltraItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Butler Stencil';
  src: url('/fonts/ButlerStencil/BrandonText.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Butler Stencil';
  src: url('/fonts/ButlerStencil/Butler_Black_Stencil.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Butler Stencil';
  src: url('/fonts/ButlerStencil/Butler_Bold_Stencil.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Butler Stencil';
  src: url('/fonts/ButlerStencil/Butler_ExtraBold_Stencil.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Butler Stencil';
  src: url('/fonts/ButlerStencil/Butler_Light_Stencil.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Butler Stencil';
  src: url('/fonts/ButlerStencil/Butler_Medium_Stencil.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Butler Stencil';
  src: url('/fonts/ButlerStencil/Butler_Regular_Stencil.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Butler Stencil';
  src: url('/fonts/ButlerStencil/Butler_Ultra_Light_Stencil.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'ITC Caslon';
  src: url('/fonts/ITC-Caslon224.ttf') format('truetype');
}

@font-face {
  font-family: 'Brandon Text';
  src: url('/fonts/BrandonText.ttf') format('truetype');
}

@font-face {
  font-family: 'Gotham';
  src: url('/fonts/Gotham/Gotham-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('/fonts/Gotham/Gotham-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham';
  src: url('/fonts/Gotham/Gotham-XLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('/fonts/Gotham/Gotham-XLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham';
  src: url('/fonts/Gotham/GothamBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('/fonts/Gotham/GothamBoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham';
  src: url('/fonts/Gotham/GothamBook.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('/fonts/Gotham/GothamBookItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham';
  src: url('/fonts/Gotham/GothamLight.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('/fonts/Gotham/GothamLightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham';
  src: url('/fonts/Gotham/GothamMedium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('/fonts/Gotham/GothamMediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Nimrod';
  src: url('/fonts/Nimrod/NimrodCyr.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Nimrod';
  src: url('/fonts/Nimrod/NimrodCyrBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Nimrod';
  src: url('/fonts/Nimrod/NimrodCyrBoldInclined.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Nimrod';
  src: url('/fonts/Nimrod/NimrodCyrInclined.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Hero Sans';
  src: url('/fonts/HeroSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Hero Serif';
  src: url('/fonts/HeroSerif-Bold.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Brother1816';
  src: url('/fonts/Brother-1816/Brother1816-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Brother1816';
  src: url('/fonts/Brother-1816/Brother1816-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Brother1816';
  src: url('/fonts/Brother-1816/Brother1816-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Brother1816';
  src: url('/fonts/Brother-1816/Brother1816-Regulartalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Catchland';
  src: url('/fonts/Catchland/Catchland-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Catchland';
  src: url('/fonts/Catchland/Catchland-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BonusArrows';
  src: url('/fonts/HHAGG/BonusArrows-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Impact';
  src: url('/fonts/HHAGG/Impact.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'JoyMaker';
  src: url('/fonts/HHAGG/JoyMaker-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'LabeckFilled';
  src: url('/fonts/HHAGG/LabeckFilled-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'LoveYaLikeASister';
  src: url('/fonts/HHAGG/LoveYaLikeASister.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MinionPro';
  src: url('/fonts/HHAGG/MinionPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OPTIEngraversOldEnglish';
  src: url('/fonts/HHAGG/OPTIEngraversOldEnglish.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Sausages';
  src: url('/fonts/HHAGG/Sausages-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Museo Slab */
@font-face {
  font-family: 'Museo Slab';
  src: url('/fonts/MuseoSlab/MuseoSlab300.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Museo Slab';
  src: url('/fonts/MuseoSlab/MuseoSlab300Italic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Museo Slab';
  src: url('/fonts/MuseoSlab/MuseoSlab500.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Museo Slab';
  src: url('/fonts/MuseoSlab/MuseoSlab500Italic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Museo Slab';
  src: url('/fonts/MuseoSlab/MuseoSlab700.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Museo Slab';
  src: url('/fonts/MuseoSlab/MuseoSlab700Italic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

/* Brothers OT */
@font-face {
  font-family: 'Brothers OT';
  src: url('/fonts/BrothersOT/BrothersOTRegular.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Brothers OT';
  src: url('/fonts/BrothersOT/BrothersOTBold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

/* Alkaline */
@font-face {
  font-family: 'Alkaline';
  src: url('/fonts/Alkaline/AlkalineRegular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Alkaline';
  src: url('/fonts/Alkaline/AlkalineMedium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Alkaline';
  src: url('/fonts/Alkaline/AlkalineBold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

/* Bongiorno */
@font-face {
  font-family: 'Bongiorno';
  src: url('/fonts/chopshop/bongiorno.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Gothic Regular */
@font-face {
  font-family: 'Gothic';
  src: url('/fonts/chopshop/GOTHIC.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Gothic Bold */
@font-face {
  font-family: 'Gothic';
  src: url('/fonts/chopshop/GOTHICB.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

/* Gothic Bold Italic */
@font-face {
  font-family: 'Gothic';
  src: url('/fonts/chopshop/GOTHICBI.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

/* Gothic Italic */
@font-face {
  font-family: 'Gothic';
  src: url('/fonts/chopshop/GOTHICI.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

/* Sketchy */
@font-face {
  font-family: 'Sketchy';
  src: url('/fonts/chopshop/Sketchy.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat Regular';
  src: url('/fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Ayr Blufy Black Regular';
  src: url('/fonts/Mugshots/AyrBlufy-BlackRegular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Festivo LC Wood';
  src: url('/fonts/Mugshots/Festivo LC Wood.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Festivo LC 3D';
  src: url('/fonts/Mugshots/FestivoLC3D.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Festivo Letters';
  src: url('/fonts/Mugshots/FontsFree-Net-FestivoLetters.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Harlow Solid';
  src: url('/fonts/Mugshots/HarlowSolid.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Nexa Rust Script H';
  src: url('/fonts/Mugshots/NexaRustScriptH-02.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Nexa Rust Script H Shadow';
  src: url('/fonts/Mugshots/NexaRustScriptH-Shadow.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Ranked Sports Regular';
  src: url('/fonts/Mugshots/RankedSports-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Calder Dark';
  src: url('/fonts/BarrioQueen/Calder/Calder-Dark.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Calder Dark Grit';
  src: url('/fonts/BarrioQueen/Calder/Calder-DarkGrit.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Calder Dark Grit Shadow';
  src: url('/fonts/BarrioQueen/Calder/Calder-DarkGritShadow.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Calder Dark Outline';
  src: url('/fonts/BarrioQueen/Calder/Calder-DarkOutline.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Calder Dark Shadow';
  src: url('/fonts/BarrioQueen/Calder/Calder-DarkShadow.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Calder Grit Shadow';
  src: url('/fonts/BarrioQueen/Calder/Calder-GritShadow.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Calder LC';
  src: url('/fonts/BarrioQueen/Calder/Calder-LC.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Calder LC Grit';
  src: url('/fonts/BarrioQueen/Calder/Calder-LCGrit.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Calder Script';
  src: url('/fonts/BarrioQueen/Calder/Calder-Script.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Calder Shadow';
  src: url('/fonts/BarrioQueen/Calder/Calder-Shadow.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Eldwin Script';
  src: url('/fonts/BarrioQueen/EldwinScript/EldwinTrial-ScriptBold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Eldwin Script';
  src: url('/fonts/BarrioQueen/EldwinScript/EldwinTrial-ScriptHeavy.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Eldwin Script';
  src: url('/fonts/BarrioQueen/EldwinScript/EldwinTrial-ScriptRegular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Eldwin Script';
  src: url('/fonts/BarrioQueen/EldwinScript/EldwinTrial-ScriptSemiBold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

/* Josefin Sans (consistent font-family name) */
@font-face {
  font-family: 'Josefin Sans';
  src: url('/fonts/BarrioQueen/Josefin_Sans/JosefinSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Josefin Sans';
  src: url('/fonts/BarrioQueen/Josefin_Sans/JosefinSans-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Josefin Sans';
  src: url('/fonts/BarrioQueen/Josefin_Sans/JosefinSans-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Josefin Sans';
  src: url('/fonts/BarrioQueen/Josefin_Sans/JosefinSans-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Josefin Sans';
  src: url('/fonts/BarrioQueen/Josefin_Sans/JosefinSans-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Josefin Sans';
  src: url('/fonts/BarrioQueen/Josefin_Sans/JosefinSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Josefin Sans';
  src: url('/fonts/BarrioQueen/Josefin_Sans/JosefinSans-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Josefin Sans';
  src: url('/fonts/BarrioQueen/Josefin_Sans/JosefinSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Josefin Sans';
  src: url('/fonts/BarrioQueen/Josefin_Sans/JosefinSans-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Josefin Sans';
  src: url('/fonts/BarrioQueen/Josefin_Sans/JosefinSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Josefin Sans';
  src: url('/fonts/BarrioQueen/Josefin_Sans/JosefinSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Josefin Sans';
  src: url('/fonts/BarrioQueen/Josefin_Sans/JosefinSans-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Josefin Sans';
  src: url('/fonts/BarrioQueen/Josefin_Sans/JosefinSans-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Josefin Sans';
  src: url('/fonts/BarrioQueen/Josefin_Sans/JosefinSans-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Abril Fatface Regular';
  src: url('/fonts/BakerSquare/AbrilFatface-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'VC Henrietta';
  src: url('/fonts/VCHenrietta-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Urbane Rounded';
  src: url('/fonts/UrbaneRounded-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Brandon';
  src: url('/fonts/Brandon/Brandon_reg.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Brandon';
  src: url('/fonts/Brandon/Brandon_bld.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Brandon';
  src: url('/fonts/Brandon/Brandon_blk.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Brandon';
  src: url('/fonts/Brandon/Brandon_light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Brandon';
  src: url('/fonts/Brandon/Brandon_med.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Brandon';
  src: url('/fonts/Brandon/Brandon_reg_it.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'PB Chicken Finger';
  src: url('/fonts/PBFonts/PB Chicken Finger.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PB BigPapa';
  src: url('/fonts/PBFonts/PB_BigPapa.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PB ClaraHand';
  src: url('/fonts/PBFonts/PB_ClaraHand-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PB ClaraHand';
  src: url('/fonts/PBFonts/PB_ClaraHand-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'PB LaterGator';
  src: url('/fonts/PBFonts/PB_LaterGator.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PB MiniSerif';
  src: url('/fonts/PBFonts/PB_MiniSerif-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PB MiniSerif';
  src: url('/fonts/PBFonts/PB_MiniSerif-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'PB RoughMenu';
  src: url('/fonts/PBFonts/PB_RoughMenu.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PB Signpainter';
  src: url('/fonts/PBFonts/PB_Signpainter-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PosterHand';
  src: url('/fonts/PBFonts/PosterHand-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
