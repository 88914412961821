@import './nprogress.css';
@import './TextInput.css';
@import 'rmc-picker-scroll/assets/index.css';
@import './fonts.css';

:root {
  --font-family-base: 'NotoSans', sans-serif;
  --font-family-regular: 'NotoSans-Regular', sans-serif;
  --font-family-bold: 'NotoSans-Bold', sans-serif;
  --font-family-italic: 'NotoSans-Italic', sans-serif;
  --font-family-bold-italic: 'NotoSans-BoldItalic', sans-serif;
  --font-family-light: 'NotoSans-Light', sans-serif;
  --font-family-light-italic: 'NotoSans-LightItalic', sans-serif;
  --font-family-medium: 'NotoSans-Medium', sans-serif;
  --font-family-medium-italic: 'NotoSans-MediumItalic', sans-serif;
  --font-family-semibold: 'NotoSans-SemiBold', sans-serif;
  --font-family-semibold-italic: 'NotoSans-SemiBoldItalic', sans-serif;
  --font-family-extrabold: 'NotoSans-ExtraBold', sans-serif;
  --font-family-extrabold-italic: 'NotoSans-ExtraBoldItalic', sans-serif;
  --font-family-extralight: 'NotoSans-ExtraLight', sans-serif;
  --font-family-extralight-italic: 'NotoSans-ExtraLightItalic', sans-serif;
  --font-family-thin: 'NotoSans-Thin', sans-serif;
  --font-family-thin-italic: 'NotoSans-ThinItalic', sans-serif;
  --snow: #fcfcfc;
  --white: #fff;
  --grey-dark: #30384c;
  --grey: #a0a0a0;
  --grey-light: #eaeaea;
  --blue-light: #6495ed;
  --orange: #d84339;
  --orange-light: #f4a460;
  --red-dark: #df3030;
  --red: #ff4b12;
  --red-background: #d84339;
  --green-dark: #089171;
  --green: #44d66d;
  --green-light: #cffcf2;
  --green-secondary: #0faa7e;
  --black: rgba(0, 0, 0, 0.87);
  --label: #30384c;
  --table-header: #a0a0a0;
  --chart-red: #ea4435;
  --chart-blue: #4285f4;
  --chart-yellow: #fcbc04;
  --slate-gray-dark: #30384c;
  --sandy-brown: #f4a460;
  --sea-green-light: #27c296;
  --indian-red: #d84339;
  --smoke-white: #f3f3f3;
  --light-violet: #fcfbff;
  --violet: #a477ff;
  --disable-grey: #eaeaea;
  --yellow-dark: #faad14;
}

html {
  font-size: 16px;
}

body {
  font-size: 1rem;
  font-family: var(--font-family-regular), sans-serif;
  overflow: hidden;
  height: 100%;
  overflow-y: scroll;
}

main {
  margin-top: 80px;
  padding: 25px 20px;
}

h2 {
  font-family: var(--font-family-bold);
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;
  color: var(--grey-dark);
}

@media screen and (max-width: 768px) {
  h2 {
    font-size: 24px;
    line-height: 30px;
  }
}

h5 {
  font-family: var(--font-family-bold);
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
  color: var(--grey-dark);
}

h6 {
  font-family: var(--font-family-bold);
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-transform: capitalize;
  color: var(--grey-dark);
}

p,
span {
  font-family: var(--font-family-regular);
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: var(--grey-dark);
}

.body-text-2 {
  font-family: var(--font-family-regular);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: var(--grey-dark);
}

.body-text {
  font-family: var(--font-family-regular);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: var(--grey-dark);
}

.label-text {
  font-family: var(--font-family-medium);
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 10px;
}

.text-bold {
  font-weight: bold;
}

.text-center {
  text-align: center;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.flex-direction-row {
  flex-direction: row;
}

.justify-content-center {
  justify-content: center;
}

.flex-space-between {
  justify-content: space-between;
}

.float-right {
  float: right;
}

.w-100 {
  width: 100%;
}

.mb-2 {
  margin-bottom: 0.125rem;
}

.mr-5 {
  margin-right: 0.3125rem;
}

.mr-10 {
  margin-right: 0.625rem;
}

.ml-10 {
  margin-left: 0.625rem;
}

.mb-10 {
  margin-bottom: 0.625rem;
}

.mb-5 {
  margin-bottom: 0.3125rem;
}

.mb-15 {
  margin-bottom: 0.9375rem;
}

.mb-25 {
  margin-bottom: 1.5625rem;
}

.ml-5 {
  margin-left: 0.3125rem;
}

.mr-15 {
  margin-right: 0.9375rem;
}

.mt-20 {
  margin-top: 1.25rem;
}

.mt-50 {
  margin-top: 3.125rem;
}

.mt-5 {
  margin-top: 0.3125rem;
}

.mt-10 {
  margin-top: 0.625rem;
}

.mt-40 {
  margin-top: 2.5rem;
}

.mt-30 {
  margin-top: 1.875rem;
}

.mb-20 {
  margin-bottom: 1.25rem;
}

.mr-20 {
  margin-right: 1.25rem;
}

.mb-30 {
  margin-bottom: 1.875rem;
}

.mb-40 {
  margin-bottom: 2.5rem;
}

.underline {
  text-decoration: underline;
}

.flex-1 {
  flex: 1;
}

.full-width-modal .ant-modal {
  max-width: 100%;
  top: 0;
}

.primary-color {
  color: var(--orange);
}

.grey-color {
  color: var(--grey);
}

.font-600 {
  font-weight: 600;
  font-family: var(--font-family-medium);
}

.font-medium {
  font-family: var(--font-family-medium);
}

.ant-checkbox-wrapper span {
  font-family: var(--font-family-regular);
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 1.25rem;
  color: var(--grey-dark);
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--orange);
  border-color: var(--orange);
}

.ant-checkbox-inner {
  width: 1rem;
  height: 1rem;
}

.ant-checkbox-inner::after {
  width: 0.357rem;
  height: 0.571rem;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: var(--grey-dark);
}

.ant-notification-notice-with-icon .ant-notification-notice-message {
  font-size: 18px;
}

.ant-notification-notice-with-icon .ant-notification-notice-description {
  font-size: 16px;
}

@media screen and (max-width: 767px) {
  .full-width-modal .ant-modal {
    margin: 0px;
  }
}

svg {
  vertical-align: baseline;
}

.momos-modal .ant-modal-content {
  background: #ffffff;
  border-radius: 16px;
}

.ml-20 {
  margin-left: 20px;
}

.tnc-wrap {
  background: #ffffff;
  box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  padding: 15px 20px;
  margin: 20px 0px;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.back-button-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.back-button-wrap .back-title {
  font-weight: bold;
}

.mb-0 {
  margin-bottom: 0;
}

.flex {
  display: flex;
}

.space-between {
  justify-content: space-between;
}

.flex-center {
  justify-content: center;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.cursor-pointer {
  cursor: pointer;
}
.m-20-auto {
  margin: 20px auto;
}

.hidden {
  display: none !important;
}

.w-full {
  width: 100%;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mt-3 {
  margin-top: 0.75rem;
}

.mb-3 {
  margin-bottom: 0.75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.m-auto {
  margin: auto;
}

.fi-us {
  background-image: url(/images/flags/us.svg);
  height: 12px;
  width: 15px;
  display: block;
  background-repeat: no-repeat;
}

.fi-cn {
  background-image: url(/images/flags/cn.svg);
  height: 12px;
  width: 15px;
  display: block;
  background-repeat: no-repeat;
}

.fi-es {
  background-image: url(/images/flags/es.svg);
  height: 12px;
  width: 15px;
  display: block;
  background-repeat: no-repeat;
}

.fi-gb {
  background-image: url(/images/flags/gb.svg);
  height: 12px;
  width: 15px;
  display: block;
  background-repeat: no-repeat;
}

.fi-id {
  background-image: url(/images/flags/id.svg);
  height: 12px;
  width: 15px;
  display: block;
  background-repeat: no-repeat;
}

.fi-my {
  background-image: url(/images/flags/my.svg);
  height: 12px;
  width: 15px;
  display: block;
  background-repeat: no-repeat;
}

.fi-ph {
  background-image: url(/images/flags/ph.svg);
  height: 12px;
  width: 15px;
  display: block;
  background-repeat: no-repeat;
}

.fi-sa {
  background-image: url(/images/flags/sa.svg);
  height: 12px;
  width: 15px;
  display: block;
  background-repeat: no-repeat;
}

.fi-th {
  background-image: url(/images/flags/th.svg);
  height: 12px;
  width: 15px;
  display: block;
  background-repeat: no-repeat;
}

.fi-tw {
  background-image: url(/images/flags/tw.svg);
  height: 12px;
  width: 15px;
  display: block;
  background-repeat: no-repeat;
}

/* @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
  html {
    font-size: 32px;
  }
} */

/* portrairt css */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  html {
    font-size: 25px;
  }
}

/* landscape css */
@media only screen and (min-width: 1024px) and (max-width: 1366px) {
  html {
    font-size: 20px;
  }
}
